import axios from 'axios';
import { env } from '../utils/env';

export const UploadFile = async ({ file, jwt }) => {
  try {
    const formdata = new FormData();
    formdata.append('file', file);

    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/file/upload`,
      data: formdata,
      headers: {
        Authorization: `Bearer ${jwt}`,
        'content-type': 'multipart/form-data',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
