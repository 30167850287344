import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useApi } from '../hooks/useApi';
import { useCookies } from 'react-cookie';
import { GetAllPromocodes } from '../api/promocode';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';

export const PromocodesPage = () => {
  const [promocodes, setPromocodes] = useState([]);
  const GetAllPromocodesApi = useApi(GetAllPromocodes);
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();
  useEffect(() => {
    GetAllPromocodesApi.sendRequest({ jwt: cookie.jwt }).then((result) =>
      setPromocodes(result),
    );
  }, []);

  return (
    <MainLayout title="Промокоды">
      <Button
        variant="outlined"
        sx={{ mb: 3 }}
        onClick={() => navigate(url.createPromocode)}
      >
        Добавить промокод
      </Button>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Тип</TableCell>
              <TableCell>Величина</TableCell>
              <TableCell>Лимит</TableCell>
              <TableCell>Срок действия</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promocodes?.map((promocode) => (
              <TableRow
                key={promocode.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => navigate(`/promocodes/${promocode.id}`)}
              >
                <TableCell>{promocode.name}</TableCell>
                <TableCell>
                  {promocode.type == 'percent' ? 'Процент' : 'Сумма'}
                </TableCell>
                <TableCell>{promocode.value}</TableCell>
                <TableCell>
                  {promocode.limit == '-1' ? 'Без лимитный' : promocode?.limit}
                </TableCell>
                <TableCell>
                  {format(promocode.expire, 'dd.MM.yyyy HH:ss:ii')}
                </TableCell>
                <TableCell>
                  {format(promocode.created, 'dd.MM.yyyy HH:ss:ii')}
                </TableCell>
                <TableCell>
                  {format(promocode.updated, 'dd.MM.yyyy HH:ss:ii')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainLayout>
  );
};
