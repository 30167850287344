import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useApi } from '../hooks/useApi';
import { GetDashboardAnalytics } from '../api/analytics';
import { useCookies } from 'react-cookie';
import { Card, CardContent, Grid, Typography } from '@mui/material';

export const HomePage = () => {
  const [stats, setStats] = useState({});
  const GetDashboardAnalyticsApi = useApi(GetDashboardAnalytics);

  const [cookie, setCookie] = useCookies('jwt');

  useEffect(() => {
    if (cookie.jwt) {
      GetDashboardAnalyticsApi.sendRequest({ jwt: cookie.jwt }).then((result) =>
        setStats(result),
      );
    }

    // "cart": 0,
    //       "customer": 1,
    //       "order": 2,
    //       "orderPayments": 1,
    //       "product": 20694,
    //       "productVariation": 32260,
    //       "wishlist": 1
  }, []);
  return (
    <MainLayout title="Статистика">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Активные корзины</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.cart}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Всего клиентов</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.customer}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Всего заказов</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.order}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Всего платежей по заказам</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.orderPayments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Всего товаров</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.product} (Вариаций -{' '}
                {stats?.counts?.productVariation})
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 0 }}>Добавлено в избранное</Typography>
              <Typography as="p" variant="h6" sx={{ mb: 0 }}>
                {stats?.counts?.wishlist}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
