import axios from 'axios';
import { env } from '../utils/env';

export const EditSettings = async ({ jwt, data }) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/settings/edit`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetSettings = async () => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/settings/get`,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
