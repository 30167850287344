import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { GetOneOrder, UpdateOrderStatus } from '../api/order';
import { useCookies } from 'react-cookie';
import { format } from 'date-fns';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';

export const SingleOrderPage = () => {
  const [order, setOrder] = useState({});
  const { orderId } = useParams();
  const GetOneOrderApi = useApi(GetOneOrder);
  const UpdateOrderStatusApi = useApi(UpdateOrderStatus);
  const [cookie] = useCookies('jwt');
  const dispatch = useDispatch();
  useEffect(() => {
    if (orderId) {
      GetOneOrderApi.sendRequest({
        jwt: cookie.jwt,
        id: orderId,
      }).then((result) => setOrder(result));
    }
  }, []);
  return (
    <MainLayout
      title={`Заказ №${order.numberId} (${order.id}) от ${
        order?.created && format(order?.created, 'dd.MM.yyyy HH:ss:ii')
      }`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <FormControl sx={{ width: '500px' }}>
              <InputLabel id="demo-simple-select-label">Статус</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={order?.status}
                label="Категория"
                onChange={({ target: { value } }) => {
                  setOrder({ ...order, status: value });

                  UpdateOrderStatusApi.sendRequest({
                    jwt: cookie.jwt,
                    data: {
                      id: order.id,
                      status: value,
                    },
                  }).then((r) =>
                    dispatch(
                      setAlert({ status: 201, text: 'Заказ успешно сохранен' }),
                    ),
                  );
                }}
              >
                <MenuItem value="completed">Исполнен</MenuItem>
                <MenuItem value="created">Создан</MenuItem>
                <MenuItem value="paid">Оплачен</MenuItem>
                <MenuItem value="paymentError">Ошибка платежа</MenuItem>
                <MenuItem value="waitPayment">Ожидает оплаты</MenuItem>
                <MenuItem value="providerError">Отказ поставщика</MenuItem>
                <MenuItem value="sendToProvide">Отправлен в сборку</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell>Кол-во</TableCell>
                  <TableCell>Цена</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.products?.map((product) => (
                  <TableRow
                    key={order.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.count}</TableCell>
                    <TableCell>{product.retailPrice}</TableCell>
                  </TableRow>
                ))}
                <TableRow
                  key={order.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    Сумма товаров
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    {order?.products?.reduce(
                      (a, b) => +a?.count || 0 + +b?.count || 0,
                      0,
                    )}{' '}
                    шт.
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    {order?.products?.reduce(
                      (a, b) => +a?.retailPrice || 0 + +b?.retailPrice || 0,
                      0,
                    )}{' '}
                    руб.
                  </TableCell>
                </TableRow>
                <TableRow
                  key={order.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    Сумма доставки
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    {order?.delivery?.deliveryTotalSum}
                    руб.
                  </TableCell>
                </TableRow>

                <TableRow
                  key={order.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    Итого
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold !important' }}>
                    {+order?.delivery?.deliveryTotalSum +
                      +order?.products?.reduce(
                        (a, b) => +a?.retailPrice || 0 + +b?.retailPrice || 0,
                        0,
                      )}
                    руб.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography as="p" variant="h6">
            Доставка
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell>Значение</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Страна</TableCell>
                  <TableCell>{order?.delivery?.country}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Город</TableCell>
                  <TableCell>{order?.delivery?.city}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Улица</TableCell>
                  <TableCell>{order?.delivery?.street}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Дом</TableCell>
                  <TableCell>{order?.delivery?.house}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Квартира/помещение</TableCell>
                  <TableCell>{order?.delivery?.flat}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Почтовый индекс</TableCell>
                  <TableCell>{order?.delivery?.zip}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Тип упоковки</TableCell>
                  <TableCell>{order?.delivery?.packType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Высота</TableCell>
                  <TableCell>{order?.delivery?.height}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Вес</TableCell>
                  <TableCell>{order?.delivery?.weight}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Длина</TableCell>
                  <TableCell>{order?.delivery?.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ширина</TableCell>
                  <TableCell>{order?.delivery?.width}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography as="p" variant="h6" sx={{ my: 2 }}>
            Получатель
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell>Значение</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Имя</TableCell>
                  <TableCell>{order?.customer?.firstName}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Фамилия</TableCell>
                  <TableCell>{order?.customer?.lastName}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Отчество</TableCell>
                  <TableCell>{order?.customer?.middleName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Номер телефона</TableCell>
                  <TableCell>{order?.customer?.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{order?.customer?.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
