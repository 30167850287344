import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  GetAllCategories,
  GetAllImages,
  GetOneCategory,
  SetCategoryImage,
  UpdateCategory,
} from '../api/product';
import { v4 as uuidv4 } from 'uuid';

import { useApi } from '../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';

export const SingleCategoryPage = () => {
  const { categorySlug } = useParams();
  const GetAllCategoriesApi = useApi(GetAllCategories);
  const GetOneCategoryApi = useApi(GetOneCategory);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [cookie] = useCookies('jwt');
  const navigate = useNavigate();
  const UpdateCategoryApi = useApi(UpdateCategory);

  const GetAllImagesApi = useApi(GetAllImages);
  const SetCategoryImageApi = useApi(SetCategoryImage);
  const [rerender, setRerender] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const GetAllImagesHandler = async (categoryId) => {
    if (categoryId) {
      const result = await GetAllImagesApi.sendRequest({
        jwt: cookie.jwt,
        limit: 16,
        categoryId,
        offset: (page - 1) * 16,
      });

      setImages(result[0]);
    }
  };
  useEffect(() => {
    if (categorySlug) {
      GetCategoryHandler();
    } else {
      setRerender(uuidv4());
    }
    GetAllCategoriesApi.sendRequest({ jwt: cookie.jwt }).then((result) => {
      setCategories(result);
    });
  }, [categorySlug]);

  useEffect(() => {
    if (category?.id) {
      GetAllImagesHandler(category.id);
    }
  }, [page]);

  const GetCategoryHandler = () => {
    GetOneCategoryApi.sendRequest({
      slug: categorySlug,
      jwt: cookie.jwt,
      relations: ['parent', 'picture'],
    }).then((result) => {
      setCategory(result);
      setRerender(uuidv4());
      GetAllImagesHandler(result.id);
    });
  };

  const UpdateCategoryHandler = async () => {
    const data = category;

    const result = await UpdateCategoryApi.sendRequest({
      data: data,
      jwt: cookie.jwt,
    });

    if (!category?.id) {
      dispatch(setAlert({ status: 201, text: 'Категория успешно сохранена' }));
      navigate(`/products/${result.slug}`);
    } else {
      dispatch(setAlert({ status: 201, text: 'Категория успешно сохранена' }));
      GetCategoryHandler();
    }
  };
  return (
    <MainLayout title={category?.name}>
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <FormControl sx={{ width: '500px' }}>
          <InputLabel id="demo-simple-select-label">Категория</InputLabel>
          {((categories?.length && category?.parent) || !categorySlug) && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category?.parent?.id}
              label="Категория"
              onChange={({ target: { value } }) =>
                setCategory({ ...category, parent: { id: value } })
              }
            >
              {categories?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <TextField
          value={category?.name}
          fullWidth
          label="Название"
          onChange={({ target: { value } }) =>
            setCategory({ ...category, name: value })
          }
          sx={{ mb: 3 }}
        />
      </Stack>
      {rerender && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={category?.isPopular}
                onChange={({ target: { checked } }) =>
                  setCategory({
                    ...category,
                    isPopular: checked,
                  })
                }
              />
            }
            label="Популярная категория"
          />
        </FormGroup>
      )}
      {rerender && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={category?.isActive}
                onChange={({ target: { checked } }) =>
                  setCategory({
                    ...category,
                    isActive: checked,
                  })
                }
              />
            }
            label="Категория активна"
          />
        </FormGroup>
      )}

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            sx={{
              width: '100px',
              height: '100px',
              borderRadius: '5px',
              overflow: 'hidden',
              border: 'solid 1px red',
            }}
          >
            <img
              src={category?.picture?.url}
              style={{ widht: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Grid>
        {images?.map((image) => (
          <Grid item xs={2}>
            <Box
              sx={{
                width: '100px',
                height: '100px',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
              onClick={() =>
                SetCategoryImageApi.sendRequest({
                  data: { categoryId: category.id, imageId: image.id },
                  jwt: cookie.jwt,
                })
              }
            >
              <img
                src={image.url}
                style={{ widht: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Pagination
            count={10}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Grid>
      </Grid>
      <Stack direction="row" sx={{ pb: 4 }}>
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          onClick={UpdateCategoryHandler}
        >
          Обновить
        </Button>

        <Button
          sx={{ mt: 3, ml: 2 }}
          color="error"
          variant="contained"
          // onClick={RemoveProductHandler}
        >
          Удалить категорию
        </Button>
      </Stack>
    </MainLayout>
  );
};
