import { Box, Button, Rating, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { CreateFeedback } from '../../api/feedback';
import { useCookies } from 'react-cookie';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';

export const RaitingForm = ({ product, otherActionHandler }) => {
  const [feedback, setFeedback] = useState({});
  const CreateFeedbackApi = useApi(CreateFeedback);
  const [cookie] = useCookies('jwt');
  const dispatch = useDispatch();
  const CreateFeedbackHandeler = async () => {
    await CreateFeedbackApi.sendRequest({
      data: { ...feedback, product: { id: product.id } },
      jwt: cookie.jwt,
    });
    dispatch(setAlert({ status: 201, text: 'Отзыв успешно добавлен' }));
    otherActionHandler();
  };
  return (
    <Stack>
      <Box>
        <Rating
          name="simple-controlled"
          value={feedback?.raiting}
          onChange={(event, newValue) => {
            setFeedback({ ...feedback, raiting: newValue });
          }}
          sx={{ mb: 2 }}
        />
      </Box>
      <TextField
        label="Имя"
        sx={{ mb: 2 }}
        onChange={({ target: { value } }) =>
          setFeedback({ ...feedback, customerName: value })
        }
      />
      <TextField
        label="Отзыв"
        multiline
        rows={4}
        sx={{ mb: 2 }}
        onChange={({ target: { value } }) =>
          setFeedback({ ...feedback, text: value })
        }
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => CreateFeedbackHandeler()}
      >
        Сохранить
      </Button>
    </Stack>
  );
};
