import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useApi } from '../hooks/useApi';
import { GetAllCustomers } from '../api/customer';
import { useCookies } from 'react-cookie';
import { CreatePromocode, GetPromocode } from '../api/promocode';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { parseISO } from 'date-fns';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';

export const SinglePromocodePage = () => {
  const [promocode, setPromocode] = useState({});
  const [customers, setCustomers] = useState([]);

  const { promocodeId } = useParams();
  const [cookie] = useCookies('jwt');
  const GetAllCustomersApi = useApi(GetAllCustomers);
  const GetPromocodeApi = useApi(GetPromocode);
  const CreatePromocodeApi = useApi(CreatePromocode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    GetAllCustomersApi.sendRequest({ jwt: cookie.jwt }).then((result) =>
      setCustomers(result),
    );

    if (promocodeId) {
      GetPromocodeApi.sendRequest({
        jwt: cookie.jwt,
        id: promocodeId,
      }).then((result) => setPromocode(result));
    }
  }, []);

  const CreateHandler = async () => {
    const result = await CreatePromocodeApi.sendRequest({
      jwt: cookie.jwt,
      data: promocode,
    });
    if (result?.id) {
      dispatch(setAlert({ status: 201, text: 'Промокод успешно сохранен' }));
      navigate(`/promocodes/${result?.id}`);
    }
  };
  return (
    <MainLayout title={'Промокод: ' + promocode?.name || 'Создать промокод'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Код"
            fullWidth
            sx={{ mb: 2 }}
            value={promocode?.name}
            onChange={({ target: { value } }) =>
              setPromocode({ ...promocode, name: value })
            }
          />
          <TextField
            label="Описание"
            fullWidth
            sx={{ mb: 2 }}
            value={promocode?.description}
            onChange={({ target: { value } }) =>
              setPromocode({ ...promocode, description: value })
            }
          />
          {!promocode?.unlimited && (
            <TextField
              label="Лимит"
              fullWidth
              sx={{ mb: 2 }}
              value={promocode?.unlimited ? -1 : promocode?.limit}
              onChange={({ target: { value } }) =>
                setPromocode({ ...promocode, limit: value })
              }
            />
          )}

          {(promocode?.id || !promocodeId) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={promocode?.unlimited}
                    onChange={({ target: { checked } }) => {
                      setPromocode({
                        ...promocode,
                        limit: checked ? -1 : promocode.limit,
                        unlimited: checked,
                      });
                    }}
                  />
                }
                label="Безлимитный промокод"
              />
            </FormGroup>
          )}
          {(promocode?.id || !promocodeId) && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={promocode?.forDeliveryToo}
                    onChange={({ target: { checked } }) => {
                      setPromocode({
                        ...promocode,
                        forDeliveryToo: checked,
                      });
                    }}
                  />
                }
                label="Промокод влияет на цену доставки"
              />
            </FormGroup>
          )}

          {(promocode?.id || !promocodeId) && (
            <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
              <InputLabel id="demo-simple-select-label">
                Тип промокода
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={promocode?.type}
                label="Тип промокода"
                onChange={({ target: { value } }) =>
                  setPromocode({ ...promocode, type: value })
                }
              >
                <MenuItem value="percent">Процент</MenuItem>
                <MenuItem value="amount">Сумма</MenuItem>
              </Select>
            </FormControl>
          )}

          <TextField
            label="Величина"
            fullWidth
            sx={{ mb: 2 }}
            value={promocode?.value}
            onChange={({ target: { value } }) =>
              setPromocode({ ...promocode, value: value })
            }
          />
          {((!!customers?.length && promocode?.id) || !promocodeId) && (
            <Autocomplete
              value={promocode?.personalForCustomer?.id}
              inputValue={
                (promocode?.personalForCustomer?.firstName &&
                promocode?.personalForCustomer?.lastName
                  ? promocode?.personalForCustomer?.firstName +
                    ' ' +
                    promocode?.personalForCustomer?.lastName
                  : '') +
                ' ' +
                (promocode?.personalForCustomer?.phone ||
                  promocode?.personalForCustomer?.email ||
                  promocode?.personalForCustomer?.meta?.find(
                    (m) => m?.key == 'TelegramChatId',
                  )?.value ||
                  '')
              }
              onChange={(event, newValue) =>
                setPromocode({
                  ...promocode,
                  personalForCustomer: { id: newValue.id },
                })
              }
              fullWidth
              id="controllable-states-demo"
              options={customers}
              getOptionLabel={(customer) =>
                (customer.firstName && customer.lastName
                  ? customer.firstName + ' ' + customer.lastName
                  : '') +
                ' ' +
                (customer?.phone ||
                  customer?.email ||
                  customer?.meta?.find((m) => m?.key == 'TelegramChatId')
                    ?.value ||
                  '')
              }
              sx={{ mt: 2, mb: 3 }}
              renderInput={(params) => (
                <TextField {...params} label="Пользователь" fullWidth />
              )}
            />
          )}
          {(promocode?.id || !promocodeId) && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Срок действия"
                sx={{
                  width: '100%',
                  mb: 3,
                }}
                value={promocode.expire}
                // promocode.expire ? parseISO(promocode.expire) : null}
                onChange={(newValue) =>
                  setPromocode({ ...promocode, expire: newValue })
                }
              />
            </LocalizationProvider>
          )}

          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Basic date picker" />
            </DemoContainer>
          </LocalizationProvider> */}
          <Button variant="contained" sx={{ mb: 3 }} onClick={CreateHandler}>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
