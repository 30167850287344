import axios from 'axios';
import { env } from '../utils/env';

export const GetAllPromocodes = async ({ jwt }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/promocode/get-all`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetPromocode = async ({ jwt, id }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/promocode/get-one/${id}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const CreatePromocode = async ({ jwt, data }) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/promocode/create`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
