import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useApi } from '../hooks/useApi';
import { EditSettings, GetSettings } from '../api/settings';

import { HexColorPicker } from 'react-colorful';
import { useCookies } from 'react-cookie';

import { FileUpload } from '../components/FileUpload/FileUpload';
import { UploadFile } from '../api/file';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';

export const SettingsPage = () => {
  const [settings, setSettings] = useState({
    img: {
      logo: null,
    },
  });
  const [filesState, setFilesState] = useState([]);
  const GetSettingsApi = useApi(GetSettings);
  const EditSettingsApi = useApi(EditSettings);
  const UploadFileApi = useApi(UploadFile);
  const [cookie] = useCookies('jwt');

  const dispatch = useDispatch();

  useEffect(() => {
    GetSettingsApi.sendRequest().then((result) => {
      setSettings(result);
    });
  }, []);

  const EditSettingsHandler = async () => {
    const data = settings;
    if (filesState[0]) {
      const logo = await UploadFileApi.sendRequest({
        file: filesState[0],
        jwt: cookie.jwt,
      });

      data.img = {
        ...(data.img || {}),
        logo: logo.location,
      };
    }
    EditSettingsApi.sendRequest({ jwt: cookie.jwt, data }).then((r) =>
      dispatch(setAlert({ status: 201, text: 'Настройки успешно сохранены' })),
    );
  };

  return (
    <MainLayout title="Настройки сайта">
      <Typography
        as="h2"
        variant="h5"
        sx={{ fontWeight: 'bold', mb: 2, mt: 3 }}
      >
        Общие тексты сайта
      </Typography>
      <TextField
        value={settings?.text?.mainTitle}
        onChange={({ target: { value } }) =>
          setSettings({
            ...settings,
            text: { ...(settings.text || {}), mainTitle: value },
          })
        }
        fullWidth
        label="Главный заголовок"
        sx={{ mb: 2 }}
      />

      <TextField
        value={settings?.text?.mainDescription}
        onChange={({ target: { value } }) =>
          setSettings({
            ...settings,
            text: { ...(settings.text || {}), mainDescription: value },
          })
        }
        fullWidth
        label="Главное описание"
        sx={{ mb: 2 }}
      />

      <FileUpload
        title="Логотип"
        loadedFiles={
          settings?.img?.logo ? [{ location: settings?.img?.logo }] : []
        }
        multiply={false}
        showFromUrl={true}
        setFilesState={(data) => setFilesState(data)}
        filesState={filesState}
        removeLoadedFiles={(id) =>
          setSettings({
            ...settings,
            img: { ...(settings.img || {}), logo: null },
          })
        }
      />

      <Typography
        as="h2"
        variant="h5"
        sx={{ fontWeight: 'bold', mb: 2, mt: 3 }}
      >
        Настройки цветов
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Цвет</TableCell>
              <TableCell>main</TableCell>
              <TableCell>light</TableCell>
              <TableCell>dark</TableCell>
              <TableCell>contrastText</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Primary</TableCell>
              <TableCell>
                {settings?.colors?.primary?.main || '#fff'}

                <HexColorPicker
                  color={settings?.colors?.primary?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        primary: {
                          ...(settings?.colors?.primary || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.primary?.light}
                <HexColorPicker
                  color={settings?.colors?.primary?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        primary: {
                          ...(settings?.colors?.primary || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.primary?.dark}
                <HexColorPicker
                  color={settings?.colors?.primary?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        primary: {
                          ...(settings?.colors?.primary || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.primary?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.primary?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        primary: {
                          ...(settings?.colors?.primary || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Secondary</TableCell>
              <TableCell>
                {settings?.colors?.secondary?.main}
                <HexColorPicker
                  color={settings?.colors?.secondary?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        secondary: {
                          ...(settings?.colors?.secondary || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.secondary?.light}
                <HexColorPicker
                  color={settings?.colors?.secondary?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        secondary: {
                          ...(settings?.colors?.secondary || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.secondary?.dark}
                <HexColorPicker
                  color={settings?.colors?.secondary?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        secondary: {
                          ...(settings?.colors?.secondary || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.secondary?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.secondary?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        secondary: {
                          ...(settings?.colors?.secondary || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Error</TableCell>
              <TableCell>
                {settings?.colors?.error?.main}
                <HexColorPicker
                  color={settings?.colors?.error?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        error: {
                          ...(settings?.colors?.error || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.error?.light}
                <HexColorPicker
                  color={settings?.colors?.error?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        error: {
                          ...(settings?.colors?.error || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.error?.dark}
                <HexColorPicker
                  color={settings?.colors?.error?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        error: {
                          ...(settings?.colors?.error || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.error?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.error?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        error: {
                          ...(settings?.colors?.error || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Warning</TableCell>
              <TableCell>
                {settings?.colors?.warning?.main}
                <HexColorPicker
                  color={settings?.colors?.warning?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        warning: {
                          ...(settings?.colors?.warning || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.warning?.light}
                <HexColorPicker
                  color={settings?.colors?.warning?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        warning: {
                          ...(settings?.colors?.warning || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.warning?.dark}
                <HexColorPicker
                  color={settings?.colors?.warning?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        warning: {
                          ...(settings?.colors?.warning || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.warning?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.warning?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        warning: {
                          ...(settings?.colors?.warning || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Info</TableCell>
              <TableCell>
                {settings?.colors?.info?.main}
                <HexColorPicker
                  color={settings?.colors?.info?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        info: {
                          ...(settings?.colors?.info || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.info?.light}
                <HexColorPicker
                  color={settings?.colors?.info?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        info: {
                          ...(settings?.colors?.info || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.info?.dark}
                <HexColorPicker
                  color={settings?.colors?.info?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        info: {
                          ...(settings?.colors?.info || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.info?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.info?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        info: {
                          ...(settings?.colors?.info || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Success</TableCell>
              <TableCell>
                {settings?.colors?.success?.main}
                <HexColorPicker
                  color={settings?.colors?.success?.main}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        success: {
                          ...(settings?.colors?.success || {}),
                          main: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.success?.light}
                <HexColorPicker
                  color={settings?.colors?.success?.light}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        success: {
                          ...(settings?.colors?.success || {}),
                          light: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.success?.dark}
                <HexColorPicker
                  color={settings?.colors?.success?.dark}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        success: {
                          ...(settings?.colors?.success || {}),
                          dark: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>
                {settings?.colors?.success?.contrastText}
                <HexColorPicker
                  color={settings?.colors?.success?.contrastText}
                  onChange={(color) =>
                    setSettings({
                      ...(settings || {}),
                      colors: {
                        ...(settings?.colors || {}),
                        success: {
                          ...(settings?.colors?.success || {}),
                          contrastText: color,
                        },
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        sx={{ my: 3 }}
        variant="contained"
        color="primary"
        onClick={EditSettingsHandler}
      >
        Обновить настройки
      </Button>
    </MainLayout>
  );
};
