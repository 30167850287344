import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useApi } from '../hooks/useApi';
import { GetAllOrders } from '../api/order';
import { useCookies } from 'react-cookie';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const GetAllOrdersApi = useApi(GetAllOrders);
  const [cookie] = useCookies('jwt');
  useEffect(() => {
    GetAllOrdersApi.sendRequest({ jwt: cookie.jwt }).then((result) =>
      setOrders(result),
    );
  }, []);
  return (
    <MainLayout>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Дата обновления</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Сумма</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                key={order.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => navigate(`/order/${order.id}`)}
              >
                <TableCell>{order.numberId}</TableCell>
                <TableCell>
                  {format(order.created, 'dd.MM.yyyy HH:ss:ii')}
                </TableCell>
                <TableCell>
                  {format(order.updated, 'dd.MM.yyyy HH:ss:ii')}
                </TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>
                  {order.products?.reduce(
                    (a, b) => (+a.retailPrice || 0) + (+b.retailPrice || 0),
                    0,
                  ) + +order?.delivery?.deliveryTotalSum}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainLayout>
  );
};
