export const url = {
  home: '/',
  products: '/products',
  productCategory: '/products/:categorySlug/',
  productCategoryProduct: '/products/:categorySlug/:productSlug',
  orders: '/order',
  order: '/order/:orderId',
  settigns: '/settings',
  createProduct: '/create-product',
  createCategory: '/create-category',
  login: '/login',
  promocodes: '/promocodes',
  promocode: '/promocodes/:promocodeId',
  createPromocode: '/create-promocode',
};
