import React, { useEffect, useState, useRef } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useApi } from '../hooks/useApi';
import { GetAllCategories, GetProducts, UpdateCategory } from '../api/product';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { useCookies } from 'react-cookie';

export const ProductsPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const GetAllCategoriesApi = useApi(GetAllCategories);
  const GetProductsApi = useApi(GetProducts);
  const UpdateCategoryApi = useApi(UpdateCategory);
  const [cookie] = useCookies('jwt');
  const ref = useRef();

  useEffect(() => {
    GetAllCategoriesApi.sendRequest().then((result) => setCategories(result));
    GetProductsHandler({ s: '', categorySlug: '' });
  }, []);

  const GetProductsHandler = async ({ s = search, categorySlug = '' }) => {
    const result = await GetProductsApi.sendRequest({
      search: s,
      categorySlug,
    });
    setProducts(result[0]);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setCategories((array) => {
      let newArray = arrayMoveImmutable(array, oldIndex, newIndex);
      newArray = newArray.map((item, index) => ({
        ...item,
        order: index,
      }));

      for (const c of newArray) {
        UpdateCategoryApi.sendRequest({ data: c, jwt: cookie.jwt });
      }
      return newArray;
    });
  };

  const onSortEndChildren = (oldIndex, newIndex, parentId) => {
    setCategories((array) => {
      const parent = array.find((a) => a.id == parentId);
      let newChildrenArray = arrayMoveImmutable(
        parent.children,
        oldIndex,
        newIndex,
      );
      newChildrenArray = newChildrenArray.map((item, index) => ({
        ...item,
        order: index,
      }));

      for (const c of newChildrenArray) {
        UpdateCategoryApi.sendRequest({ data: c, jwt: cookie.jwt });
      }
      return [
        ...array.filter((a) => a.id !== parentId),
        { ...parent, children: newChildrenArray },
      ].sort((a, b) => (a.order < b.order ? -1 : 1));
    });
  };

  return (
    <MainLayout title="Товары">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextField
            label="Поиск"
            fullWidth
            onChange={({ target: { value } }) =>
              GetProductsHandler({ s: value })
            }
            sx={{ mb: 3 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            variant="outlined"
            sx={{ mb: 2 }}
            onClick={() => navigate(url?.createCategory)}
          >
            Добавить категорию
          </Button>
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged"
          >
            {categories?.map((category, index) => (
              <SortableItem ref={ref} key={index}>
                <Accordion key={category.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={'id-' + category.id}
                    sx={{ width: '100%  ' }}
                  >
                    <Stack
                      key={category.id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() =>
                        GetProductsHandler({
                          s: search,
                          categorySlug: category.slug,
                        })
                      }
                    >
                      <SortableKnob>
                        <IconButton
                          aria-label="dragndrop"
                          size="small"
                          sx={{ fontSize: '17px' }}
                          ref={ref}
                        >
                          <DragIndicatorOutlinedIcon />
                        </IconButton>
                      </SortableKnob>
                      <Box>
                        <Typography>{category.name}</Typography>
                      </Box>
                      <Stack>
                        <IconButton
                          onClick={() => navigate(`/products/${category.slug}`)}
                        >
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SortableList
                      onSortEnd={(oldIndex, newIndex) =>
                        onSortEndChildren(oldIndex, newIndex, category.id)
                      }
                      className="list"
                      draggedItemClassName="dragged"
                    >
                      {category?.children
                        ?.sort((a, b) => (a.order < b.order ? -1 : 1))
                        ?.map((children) => (
                          <SortableItem ref={ref} key={index}>
                            <Stack
                              key={children.id}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              onClick={() =>
                                GetProductsHandler({
                                  s: search,
                                  categorySlug: children.slug,
                                })
                              }
                              sx={{ width: '100%' }}
                            >
                              <Stack direction="row" spacing={2}>
                                <SortableKnob>
                                  <IconButton
                                    aria-label="dragndrop"
                                    size="small"
                                    sx={{ fontSize: '17px' }}
                                    ref={ref}
                                  >
                                    <DragIndicatorOutlinedIcon />
                                  </IconButton>
                                </SortableKnob>
                                <Box>
                                  <Typography>{children.name}</Typography>
                                </Box>
                              </Stack>
                              <Stack>
                                <IconButton
                                  onClick={() =>
                                    navigate(`/products/${children.slug}`)
                                  }
                                >
                                  <CreateOutlinedIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </SortableItem>
                        ))}
                    </SortableList>
                  </AccordionDetails>
                </Accordion>
              </SortableItem>
            ))}
          </SortableList>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Button
            variant="outlined"
            onClick={() => navigate(url?.createProduct)}
            sx={{ mb: 2 }}
          >
            Добавить товар
          </Button>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="right">Дата обновления</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() =>
                      navigate(
                        `/products/${product.category.slug}/${product.slug}`,
                      )
                    }
                  >
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell align="right">
                      {format(product.updated, 'dd.MM.yyyy HH:ss:ii')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
