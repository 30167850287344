// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertsList_AlertsList__HFuJQ {
  position: fixed;
  left: 15px;
  bottom: 0;
  width: 100%;
  max-width: 350px;
  z-index: 9999;
}
.AlertsList_AlertsList__Item__dLy2Z {
  margin-bottom: 10px;
}
.AlertsList_AlertsList__Item__Remove__5EFVo {
  transform: translateX(100px, 2s);
}`, "",{"version":3,"sources":["webpack://./src/components/AlertList/AlertsList.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;AAER;AADQ;EACI,gCAAA;AAGZ","sourcesContent":[".AlertsList {\n    position: fixed;\n    left: 15px;\n    bottom: 0;\n    width: 100%;\n    max-width: 350px;\n    z-index: 9999;\n    &__Item {\n        margin-bottom: 10px;\n        &__Remove {\n            transform: translateX(100px, 2s);\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertsList": `AlertsList_AlertsList__HFuJQ`,
	"AlertsList__Item": `AlertsList_AlertsList__Item__dLy2Z`,
	"AlertsList__Item__Remove": `AlertsList_AlertsList__Item__Remove__5EFVo`
};
export default ___CSS_LOADER_EXPORT___;
