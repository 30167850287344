import axios from 'axios';
import { env } from '../utils/env';

export const GetAllOrders = async ({ jwt }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/order/get-all`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetOneOrder = async ({ jwt, id }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/order/get-one-by-id/${id}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateOrderStatus = async ({ jwt, data }) => {
  try {
    let config = {
      method: 'put',
      url: `${env.BACKEND_URL}/order/update-status`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
