import styled from '@emotion/styled';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const FileUpload = ({
  loadedFiles,
  filesState,
  setFilesState,
  removeLoadedFiles,
  multiply = true,
  title = 'Изображения',
  showFromUrl = false,
}) => {
  const VisuallyHiddenInput = styled('input')({
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    opacity: 0,
    zIndex: 9,
  });

  return (
    <>
      <Typography sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>{title}</Typography>
      <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap' }}>
        {(!multiply || (filesState?.length < 1 && loadedFiles?.length < 1)) && (
          <Card
            sx={{
              width: '120px',
              height: '120px',
              borderRadius: '5px',
              mr: 1,
              mb: 1,
              color: '#fff',
              background: '#1d276c',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <CardContent>
              <VisuallyHiddenInput
                type="file"
                multiple={multiply}
                onChange={({ target: { files } }) =>
                  setFilesState([...filesState, ...files])
                }
              />
              <CloudUploadIcon sx={{ mt: 2 }} />
              <Typography>Добавить</Typography>
            </CardContent>
          </Card>
        )}

        {loadedFiles
          ?.filter((f) => f)
          ?.map((f) => (
            <Card
              sx={{
                width: '120px',
                height: '120px',
                borderRadius: '5px',
                mr: 1,
                mb: 1,
                position: 'relative',
              }}
            >
              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  top: '5px',
                  right: '5px',
                }}
                onClick={() => removeLoadedFiles(f.id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <img
                src={f?.url || f?.location}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Card>
          ))}

        {filesState?.map((f, index) => (
          <Card
            sx={{
              width: '120px',
              height: '120px',
              borderRadius: '5px',
              mr: 1,
              mb: 1,
              position: 'relative',
            }}
          >
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: '5px',
                right: '5px',
              }}
              onClick={() =>
                setFilesState([...filesState?.filter((ff, i) => i !== index)])
              }
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <img
              src={URL.createObjectURL(f)}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Card>
        ))}
      </Stack>
    </>
  );
};
