import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { url } from './utils/url';
import { HomePage } from './pages/HomePage';
import { OrdersPage } from './pages/OrdersPage';
import { SingleOrderPage } from './pages/SingleOrderPage';
import { SingleCategoryPage } from './pages/SingleCategoryPage';
import { SingleProductPage } from './pages/SingleProductPage';
import { ProductsPage } from './pages/ProductsPage';
import { SettingsPage } from './pages/SettingsPage';
import { LoginPage } from './pages/LoginPage';
import { PromocodesPage } from './pages/PromocodesPage';
import { SinglePromocodePage } from './pages/SinglePromocodePage';
const theme = createTheme({
  palette: {
    primary: {
      main: '#303E9F',
      light: '#303E9F',
      dark: '#1d276c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#303E9F12',
      light: '#4b5ac612',
      dark: '#29358712',
      contrastText: '#303E9F',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path={url.home} element={<HomePage />} />
          <Route exact path={url.products} element={<ProductsPage />} />
          <Route
            exact
            path={url.productCategory}
            element={<SingleCategoryPage />}
          />
          <Route
            exact
            path={url.productCategoryProduct}
            element={<SingleProductPage />}
          />
          <Route
            exact
            path={url.createProduct}
            element={<SingleProductPage />}
          />
          <Route
            exact
            path={url.createCategory}
            element={<SingleCategoryPage />}
          />
          <Route exact path={url.order} element={<SingleOrderPage />} />
          <Route exact path={url.orders} element={<OrdersPage />} />
          <Route exact path={url.settigns} element={<SettingsPage />} />
          <Route exact path={url.login} element={<LoginPage />} />
          <Route exact path={url.promocodes} element={<PromocodesPage />} />
          <Route exact path={url.promocode} element={<SinglePromocodePage />} />
          <Route
            exact
            path={url.createPromocode}
            element={<SinglePromocodePage />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
