import axios from 'axios';
import { env } from '../utils/env';

export const GetAllCategories = async () => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/product-category/get-all-recursive`,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetAllImages = async ({ jwt, limit, offset, categoryId }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/product/get-all-images`,
      params: {
        limit,
        offset,
        categoryId,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetProducts = async ({ search = '', categorySlug = '' }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/product/filter`,
      params: {
        search,
        categorySlug,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetOneProduct = async ({ slug, jwt, relations = [] }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/product/get-one-by-alias/${slug}`,
      params: {
        relations,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const GetOneCategory = async ({ slug, jwt, relations = [] }) => {
  try {
    let config = {
      method: 'get',
      url: `${env.BACKEND_URL}/product-category/get-one-by-alias/${slug}`,
      params: {
        relations,
      },
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateProduct = async ({ data, jwt }) => {
  try {
    let config = {
      method: 'patch',
      url: `${env.BACKEND_URL}/product/update`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const SetCategoryImage = async ({ data, jwt }) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/product-category/set-category-image`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateCategory = async ({ data, jwt }) => {
  try {
    let config = {
      method: 'patch',
      url: `${env.BACKEND_URL}/product-category/update`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const RemoveProduct = async ({ id, jwt }) => {
  try {
    let config = {
      method: 'delete',
      url: `${env.BACKEND_URL}/product/remove/${id}`,

      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
