import axios from 'axios';
import { env } from '../utils/env';

export const LoginAdmin = async (data) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/admin/auth`,
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
