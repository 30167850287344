import { Button, Card, CardContent, Container, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../hooks/useApi';
import { LoginAdmin } from '../api/admin';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { url } from '../utils/url';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/alert.store';
import { AlertsList } from '../components/AlertList/AlertList';

export const LoginPage = () => {
  const [data, setData] = useState({});
  const loginApi = useApi(LoginAdmin);
  const alerts = useSelector((state) => state.alerts);

  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies('jwt');
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookie.jwt) {
      navigate(url.home);
    }
  }, [cookie]);

  const LoginHandler = async () => {
    const { accessToken } = await loginApi.sendRequest(data);
    setCookie('jwt', accessToken);
    navigate(url.home);
  };
  return (
    <Container maxWidth="sm">
      <AlertsList alerts={alerts} />
      <Card>
        <CardContent>
          <TextField
            label="email"
            fullWidth
            sx={{ mb: 3 }}
            onChange={({ target: { value } }) =>
              setData({ ...data, email: value })
            }
          />
          <TextField
            label="password"
            fullWidth
            type="password"
            sx={{ mb: 3 }}
            onChange={({ target: { value } }) =>
              setData({ ...data, password: value })
            }
          />
          <Button variant="contained" color="primary" onClick={LoginHandler}>
            Вход
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};
