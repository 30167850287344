import {
  AppBar,
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../utils/url';
import { useCookies } from 'react-cookie';
import { AlertsList } from '../AlertList/AlertList';
import { useSelector } from 'react-redux';

export const MainLayout = ({ children, title }) => {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies('jwt');
  const alerts = useSelector((state) => state.alerts);

  useEffect(() => {
    if (!cookie.jwt) {
      navigate(url.login);
    }
  }, [cookie]);
  return (
    <>
      <AlertsList alerts={alerts} />
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <MenuItem onClick={() => navigate(url.home)}>
              <Typography textAlign="center">Статистика</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate(url.products)}>
              <Typography textAlign="center">Товары</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate(url.promocodes)}>
              <Typography textAlign="center">Промокоды</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate(url.orders)}>
              <Typography textAlign="center">Заказы</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate(url.settigns)}>
              <Typography textAlign="center">Настройки</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeCookie('jwt');
                navigate(url.login);
              }}
              sx={{ ml: 'auto' }}
            >
              <Typography textAlign="center">Выход</Typography>
            </MenuItem>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl">
        <Grid container spacing={2} sx={{ py: 5 }}>
          <Grid item xs={12}>
            <Typography as="h1" variant="h5">
              {title}
            </Typography>
          </Grid>
        </Grid>
        {children}
      </Container>
    </>
  );
};
