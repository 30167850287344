import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import {
  GetAllCategories,
  GetOneProduct,
  GetProducts,
  RemoveProduct,
  UpdateProduct,
} from '../api/product';
import { useCookies } from 'react-cookie';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Rating,
  Select,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FileUpload } from '../components/FileUpload/FileUpload';
import { UploadFile } from '../api/file';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { RaitingForm } from '../components/Raiting/RaitingForm';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { url } from '../utils/url';
import { setAlert } from '../store/alert.store';
import { useDispatch } from 'react-redux';

export const SingleProductPage = () => {
  const { productSlug } = useParams();
  const GetOneProductApi = useApi(GetOneProduct);
  const UpdateProductApi = useApi(UpdateProduct);
  const GetAllCategoriesApi = useApi(GetAllCategories);
  const GetAllProductsApi = useApi(GetProducts);
  const UploadFileApi = useApi(UploadFile);
  const RemoveProductApi = useApi(RemoveProduct);
  const [cookie] = useCookies('jwt');
  const [product, setProduct] = useState({});
  const [filesState, setFilesState] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [tab, setTab] = useState('1');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    GetAllProductsApi.sendRequest({}).then((result) =>
      setAllProducts(result[0]),
    );
    if (productSlug && productSlug !== 'create') {
      GetProductHandler();
    }
    GetAllCategoriesApi.sendRequest({ jwt: cookie.jwt }).then((result) =>
      setCategories(result),
    );
  }, [productSlug]);

  const GetProductHandler = () => {
    GetOneProductApi.sendRequest({
      slug: productSlug,
      jwt: cookie.jwt,
      relations: [
        'price',
        'parameters',
        'variations',
        'pictures',
        'labels',
        'category',
        'feedback',
        'crossSaleProducts',
      ],
    }).then((result) => setProduct(result));
  };
  const UpdateProductHandler = async () => {
    const data = product;
    if (filesState?.length) {
      let pictures = [];
      for (const f of filesState) {
        const p = await UploadFileApi.sendRequest({
          file: f,
          jwt: cookie.jwt,
        });

        pictures.push(p);
      }
      pictures = pictures.map((p, index) => {
        return {
          originalname: p.originalname,
          isSmall: index == 0 ? true : false,
          url: p.location,
        };
      });

      data.pictures = [
        ...(data?.pictures?.length ? data?.pictures : []),
        ...pictures,
      ];
    }

    const result = await UpdateProductApi.sendRequest({
      data: { ...data, prodID: data.prodID || uuidv4() },
      jwt: cookie.jwt,
    });

    if (!product?.id) {
      dispatch(setAlert({ status: 201, text: 'Товар успешно сохранен' }));

      navigate(
        `/products/${
          categories?.find((c) => c.id == result.category.id).slug
        }/${result.slug}`,
      );
    }
  };

  const RemoveProductHandler = async () => {
    const result = await RemoveProductApi.sendRequest({
      id: product.id,
      jwt: cookie.jwt,
    });
    navigate(url.products);
    dispatch(setAlert({ status: 201, text: 'Товар удален' }));
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <MainLayout title={product?.name}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Основные параметры" value="1" />
            <Tab label="Отзывы" value="2" disabled={!product?.id} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              <FormControl sx={{ width: '500px' }}>
                <InputLabel id="demo-simple-select-label">Категория</InputLabel>
                {((categories?.length && product?.category) ||
                  !productSlug) && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={product?.category?.id}
                    label="Категория"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, category: { id: value } })
                    }
                  >
                    {categories?.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <TextField
                value={product.name}
                fullWidth
                label="Название"
                onChange={({ target: { value } }) =>
                  setProduct({ ...product, name: value })
                }
                sx={{ mb: 3 }}
              />
            </Stack>
            <TextField
              fullWidth
              rows={4}
              multiline
              label="Описание"
              value={product.description}
              onChange={({ target: { value } }) =>
                setProduct({ ...product, description: value })
              }
              sx={{ mb: 3 }}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="розничная цена"
                value={product?.price?.retailPrice || 0}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    price: {
                      ...product?.price,
                      retailPrice: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Оптовая цена"
                value={product?.price?.wholePrice || 0}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    price: {
                      ...product?.price,
                      wholePrice: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Скидка %"
                value={product?.price?.discount || 0}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    price: {
                      ...product?.price,
                      discount: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 3 }}>
              <TextField
                fullWidth
                label="Вес"
                value={product?.parameters?.brutto || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      brutto: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Упаковка"
                value={product?.parameters?.pack || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      pack: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Материал"
                value={product?.parameters?.material || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      material: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Длина"
                value={product?.parameters?.length || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      length: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Диаметр"
                value={product?.parameters?.diameter || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      diameter: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                label="Название коллекции"
                value={product?.parameters?.collectionName || ''}
                onChange={({ target: { value } }) =>
                  setProduct({
                    ...product,
                    parameters: {
                      ...product?.parameters,
                      collectionName: value,
                    },
                  })
                }
                sx={{ mb: 3 }}
              />
            </Stack>
            <Typography as="p" variant="h5" sx={{ mb: 2 }}>
              Вариации
            </Typography>
            {product?.variations?.map((variation, index) => (
              <Stack key={index} direction="row" spacing={2} sx={{ mb: 2 }}>
                <TextField
                  label="Цвет"
                  value={variation?.color}
                  onChange={({ target: { value } }) =>
                    setProduct({
                      ...product,
                      variations: [
                        ...product?.variations?.map((v) => {
                          if (
                            v.id == variation.id ||
                            v?.aID == variation?.aID
                          ) {
                            return {
                              ...v,
                              color: value,
                            };
                          }
                          return v;
                        }),
                      ],
                    })
                  }
                />
                <TextField
                  label="Размер"
                  value={variation?.size}
                  onChange={({ target: { value } }) =>
                    setProduct({
                      ...product,
                      variations: [
                        ...product?.variations?.map((v) => {
                          if (
                            v.id == variation.id ||
                            v?.aID == variation?.aID
                          ) {
                            return {
                              ...v,
                              size: value,
                            };
                          }
                          return v;
                        }),
                      ],
                    })
                  }
                />
                <TextField
                  label="Количество"
                  value={variation?.stock}
                  onChange={({ target: { value } }) =>
                    setProduct({
                      ...product,
                      variations: [
                        ...product?.variations?.map((v) => {
                          if (
                            v.id == variation.id ||
                            v?.aID == variation?.aID
                          ) {
                            return {
                              ...v,
                              stock: value,
                            };
                          }
                          return v;
                        }),
                      ],
                    })
                  }
                />
                <IconButton
                  onClick={() =>
                    setProduct({
                      ...product,
                      variations: [
                        ...product?.variations?.filter(
                          (v) => v.aID !== variation.aID,
                        ),
                      ],
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Stack>
            ))}

            <Button
              sx={{ mt: 3 }}
              color="primary"
              variant="contained"
              onClick={() =>
                setProduct({
                  ...product,
                  variations: [
                    ...(product?.variations?.length ? product?.variations : []),
                    { aID: uuidv4() },
                  ],
                })
              }
            >
              Добавить вариацию
            </Button>
            <Typography as="p" variant="h5" sx={{ mb: 2, mt: 3 }}>
              Другое
            </Typography>
            {(product?.id || product?.labels || !productSlug) && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.labels?.isPopular}
                      onChange={({ target: { checked } }) =>
                        setProduct({
                          ...product,
                          labels: {
                            ...product.labels,
                            isPopular: checked,
                          },
                        })
                      }
                    />
                  }
                  label="Популярный товар"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.labels?.isRecomend}
                      onClick={({ target: { checked } }) =>
                        setProduct({
                          ...product,
                          labels: {
                            ...product.labels,
                            isRecomend: checked,
                          },
                        })
                      }
                    />
                  }
                  label="Рекомендованный товар"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.labels?.isSale}
                      onClick={({ target: { checked } }) =>
                        setProduct({
                          ...product,
                          labels: {
                            ...product.labels,
                            isSale: checked,
                          },
                        })
                      }
                    />
                  }
                  label="Товар по скидке"
                />
              </FormGroup>
            )}

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">
                Кросс-сейл товары
              </InputLabel>

              {(product?.id || !productSlug) && (
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={product?.crossSaleProducts?.map((p) => p?.id) || []}
                  onChange={({ target: { value } }) => {
                    setProduct({
                      ...product,
                      crossSaleProducts: value?.map((v) =>
                        allProducts?.find((p) => p.id == v),
                      ),
                    });
                  }}
                  input={<OutlinedInput label="Кросс-сейл товары" />}
                >
                  {allProducts.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>

            <FileUpload
              loadedFiles={product?.pictures}
              setFilesState={(data) => setFilesState(data)}
              filesState={filesState}
              removeLoadedFiles={(id) =>
                setProduct({
                  ...product,
                  pictures: [...product?.pictures?.filter((p) => p.id !== id)],
                })
              }
            />
          </>
        </TabPanel>

        <TabPanel value="2">
          <Modal
            open={openFeedback}
            onClose={() => setOpenFeedback(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: '650px' }}>
              <Card>
                <CardContent>
                  <RaitingForm
                    product={product}
                    otherActionHandler={() => {
                      setOpenFeedback(false);
                      GetProductHandler();
                    }}
                  />
                </CardContent>
              </Card>
            </Box>
          </Modal>
          {product?.feedback?.map((f) => (
            <>
              <Box key={f?.id}>
                <Rating name="simple-controlled" value={f?.raiting} readOnly />
                <Typography sx={{ fontWeight: 'bold' }}>
                  {f.customerName}
                </Typography>
                <Typography>{f.text}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
            </>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenFeedback(true)}
          >
            Добавить отзыв
          </Button>
        </TabPanel>
      </TabContext>

      <Stack direction="row" sx={{ pb: 4, px: 3 }}>
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          onClick={UpdateProductHandler}
        >
          Обновить
        </Button>

        <Button
          sx={{ mt: 3, ml: 2 }}
          color="error"
          variant="contained"
          onClick={RemoveProductHandler}
        >
          Удалить товар
        </Button>
      </Stack>
    </MainLayout>
  );
};
