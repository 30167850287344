import axios from 'axios';
import { env } from '../utils/env';

export const CreateFeedback = async ({ data, jwt }) => {
  try {
    let config = {
      method: 'post',
      url: `${env.BACKEND_URL}/product/create-feedback`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
